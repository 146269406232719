import React from "react";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import IconMore from "@material-ui/icons/More";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import "./WBrowser.css";

const cfgRepo = {
  "wbqs.pacov.city": {
    sparqlUrlBase:
      "https://wbqs.pacov.city/proxy/wdqs/bigdata/namespace/wdq/sparql",
    instanceOfProp: "P1",
    classClassItem: "Q45",
    lang: "cs",
  },

  "wbqs01-a.hekate.club": {
    sparqlUrlBase:
      "https://wbqs01-a.hekate.club/proxy/wdqs/bigdata/namespace/wdq/sparql",
    instanceOfProp: "P6",
    classClassItem: "Q45",
    lang: "cs",
  },

  "wbqs01-b.hekate.club": {
    sparqlUrlBase:
      "https://wbqs01-b.hekate.club/proxy/wdqs/bigdata/namespace/wdq/sparql",
    instanceOfProp: "P34",
    classClassItem: "Q45",
    lang: "cs",
  },
};

class WBrowser extends React.Component {
  constructor(props) {
    super(props);
    const defaultSource = "wbqs01-b.hekate.club"
    this.state = {
      sparqlResponse: null,
      classListData: null,
      instanceListData: null,

      selectedClassIri: null,
      slectedClassLabel: null,

      selectedInstanceIri: null,
      slectedInstanceLabel: null,
      source: defaultSource,
      cfg: cfgRepo[defaultSource],
    };
    this.handleClassSelect = this.handleClassSelect.bind(this);
    this.handleInstanceSelect = this.handleInstanceSelect.bind(this);
    this.handleSelectSource = this.handleSelectSource.bind(this);
  }

  //-------------------------------

  // ziskava vsechny tridy podle jejich oznackovani
  getAllClassesExplicitQry() {
    return `
select ?class ?classLabel ?classDesc
where {
  ?class ?instanceOf ?classClass .
  ?class rdfs:label ?classLabel .
  OPTIONAL { ?class schema:description ?classDesc . }
  values (?instanceOf ?classClass) { ( wdt:${this.state.cfg.instanceOfProp}   wd:${this.state.cfg.classClassItem} ) }
}`;
  }

  // ziskava vsechny tridy podle toho, ze jsou cilm claimu instance-of
  getAllClassesByUseQry() {
    return (`
select distinct ?class ?classLabel ?classDesc
where {
  ?instance ?instanceOf ?class .
  ?class rdfs:label ?classLabel FILTER (lang(?classLabel) = 'cs').
  OPTIONAL { ?class schema:description ?classDesc  FILTER (lang(?classDesc) = 'cs') }
  values (?instanceOf ) { ( wdt:${this.state.cfg.instanceOfProp}  ) }
}`);
  }

  getAllClassesQry() {
    return this.getAllClassesByUseQry();
  }

  getClassInstances(classIri) {
    return (`
select ?instance ?instanceLabel ?instanceDesc
where {
  ?instance ?_instanceOf ?class .
  ?instance rdfs:label ?instanceLabel .
  OPTIONAL { ?instance schema:description ?instanceDesc . }
  values ( ?_instanceOf ?class ) { ( wdt:${this.state.cfg.instanceOfProp} <${classIri}> ) }
}`);
  }

  getInstancesProps(instanceIri) {
    return `
select ?instance ?prop ?propLabel ?propValue ?propValueLabel
where {
  ?instance ?directProp ?propValue .
  ?prop wikibase:directClaim ?directProp .
  ?prop rdfs:label ?propLabel FILTER (lang(?propLabel) = 'cs').
  OPTIONAL { ?propValue rdfs:label ?propValueLabel FILTER (lang(?propValueLabel) = 'cs')  } 
  values ( ?instance ) { ( <${instanceIri}> ) }
}`;
  }

  async query(sparql) {
    console.log("qry:", sparql);
    const resp = await fetch(
      `${this.state.cfg.sparqlUrlBase}?query=${encodeURIComponent(sparql)}`,
      {
        headers: {
          Accept: "application/json",
        },
      }
    );
    console.log("status:", resp.status);
    return await resp.json();
  }

  async loadClassList(){
    console.log(`loading class list from source: ${this.state.source}`)
    const classListData = await this.query(this.getAllClassesQry());
    console.log("have classes data", classListData);
    this.setState({
      classListData: classListData,
    });
  }


  async componentDidMount() {
    this.loadClassList()
  }

  async handleClassSelect(iri, label) {
    console.log(`selected class ${iri}`);
    const instanceListData = await this.query(this.getClassInstances(iri));
    console.log("have instance list", instanceListData);
    this.setState({
      instanceListData: instanceListData,
      selectedClassIri: iri,
      selectedClassLabel: label,
    });
  }

  async handleInstanceSelect(iri, label) {
    console.log(`selected instance ${iri}`);
    const propsListData = await this.query(this.getInstancesProps(iri));
    this.setState({
      propsListData: propsListData,
      selectedInstanceIri: iri,
      selectedInstanceLabel: label,
    });
  }

  async handleSelectSource(ev) {
    console.log(ev);
    const newSource = ev.target.value
    this.setState({
      source: newSource,
      cfg: cfgRepo[newSource]
    });
    setTimeout(()=>this.loadClassList(),0)
  }

  generateClassList() {
    if (this.state.classListData) {
      console.log("populating class list");
      const resp = this.state.classListData;
      const classList = resp.results.bindings.map((bind) => {
        const clsDesc = (bind["classDesc"] && bind["classDesc"].value) || "";
        return (
          <ListItem
            key={bind["class"].value}
            title={bind["class"].value}
            role={undefined}
            dense
            button
            onClick={() => {
              this.handleClassSelect(
                bind["class"].value,
                bind["classLabel"].value
              );
            }}
          >
            <ListItemIcon>
              <IconMore />
            </ListItemIcon>
            <ListItemText
              primary={bind["classLabel"].value}
              secondary={clsDesc}
            />
          </ListItem>
        );
      });
      return classList;
    } else {
      return [];
    }
  }

  generateInstanceList() {
    if (this.state.instanceListData) {
      console.log("populating instances list");
      const resp = this.state.instanceListData;
      const instanceList = resp.results.bindings.map((bind) => {
        const instDesc =
          (bind["instanceDesc"] && bind["instanceDesc"].value) || "";
        return (
          <ListItem
            key={bind["instance"].value}
            title={bind["instance"].value}
            role={undefined}
            dense
            button
            onClick={() => {
              this.handleInstanceSelect(
                bind["instance"].value,
                bind["instanceLabel"].value
              );
            }}
          >
            <ListItemText
              primary={bind["instanceLabel"].value}
              secondary={instDesc}
            ></ListItemText>
          </ListItem>
        );
      });
      return instanceList;
    } else {
      return [];
    }
  }

  generatePropsList() {
    if (this.state.propsListData) {
      console.log("populating props list");
      const resp = this.state.propsListData;
      const propsList = resp.results.bindings.map((bind, idx) => {
        let value;
        if (bind["propValue"].type === "uri") {
          if (bind["propValueLabel"]) {
            value = (
              <Grid item title={bind["propValue"].value}>
                <div
                  className="propLink"
                  onClick={() =>
                    this.handleInstanceSelect(
                      bind["propValue"].value,
                      bind["propValueLabel"].value
                    )
                  }
                >
                  {bind["propValueLabel"].value}
                </div>
              </Grid>
            );
          } else {
            value = (
              <Grid item title={bind["propValue"].value}>
                <div
                  className="propLink"
                  onClick={() =>
                    this.handleInstanceSelect(
                      bind["propValue"].value,
                      "no-label"
                    )
                  }
                >
                  no-label
                </div>
              </Grid>
            );
          }
        } else {
          value = <Grid item>{bind["propValue"].value}</Grid>;
        }
        return (
          <Grid
            container
            direction="column"
            key={`${bind["instance"].value}-${bind["prop"].value}-${idx}`}
            className="property"
          >
            <Grid item className="propName">
              {bind["propLabel"].value}
            </Grid>
            <Grid item className="propValue">
              {value}
            </Grid>
          </Grid>
        );
      });
      return propsList;
    } else {
      return [];
    }
  }

  render() {
    console.log("rendering");
    let classList = this.generateClassList();
    let instanceList = this.generateInstanceList();
    let propsList = this.generatePropsList();

    return (
      <div className="WBrowser">
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6">WBrowser</Typography>
            <span>/{this.state.selectedClassLabel}</span>
            <span>/{this.state.selectedInstanceLabel}</span>

            <FormControl>
              <InputLabel id="demo-simple-select-label">Zdroj:</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                value={this.state.source}
                onChange={this.handleSelectSource}
              >
                <MenuItem value={"wbqs01-a.hekate.club"}>
                  wbqs01-a.hekate.club
                </MenuItem>
                <MenuItem value={"wbqs01-b.hekate.club"}>
                  wbqs01-b.hekate.club
                </MenuItem>
                <MenuItem value={"wbqs.pacov.city"}>wbqs.pacov.city</MenuItem>
              </Select>
            </FormControl>
          </Toolbar>
        </AppBar>
        <Grid container direction="row">
          <Grid item xs={3}>
            <List>
              <ListSubheader>Třídy</ListSubheader>
              {classList}
            </List>
          </Grid>
          <Grid item xs={4}>
            <List>
              <ListSubheader>Instance</ListSubheader>
              {instanceList}
            </List>
          </Grid>
          <Grid container direction="column" xs={5} className="propertyList">
            <div className="title">Vlastnosti</div>
            {propsList}
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default WBrowser;
